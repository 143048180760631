/** @format */
.aboutPage {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	width: 80%;
	height: 100%;
	margin: auto;
	margin-top: 4rem;
	.bubleContainer {
		&:nth-child(10n + 6) {
			justify-self: end;
		}
		&:nth-child(10n + 7) {
			justify-self: end;
		}
		&:nth-child(10n + 8) {
			justify-self: end;
		}
		&:nth-child(10n + 9) {
			justify-self: end;
		}
		&:nth-child(10n + 10) {
			justify-self: end;
		}
	}
}
@media screen and (max-width: 768px) {
	.aboutPage {
		grid-template-columns: repeat(2, 1fr);
	}
}
