/** @format */

.heroBanner {
	width: 100%;
	display: flex;
	background-color: #008037;
	align-items: center;
	justify-content: space-around;
	color: whitesmoke;
	height: 80%;
	img {
		aspect-ratio: 1/1;
		border-radius: 50%;
		background-color: whitesmoke;
	}
	.leftHeroSection {
		max-width: 35%;
		padding: 0.75rem;
		h1 {
			font-weight: 700;
			font-size: 48px;
		}
		p {
			text-align: left;
			font-weight: 300;
			font-size: 1.25rem;
		}
	}
}
@media screen and (max-width: 768px) {
	.heroBanner {
		flex-direction: column;
		justify-content: space-evenly;
		img {
			width: 50%;
		}
		.leftHeroSection {
			display: flex;
			padding: 1rem;
			flex-direction: column;
			max-width: unset;
		}
	}
}
