/** @format */

header {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 10vh;
	background-color: #008037;
	color: whitesmoke;
	a:visited {
		text-decoration: none;
	}
	a {
		text-decoration: none;
		color: whitesmoke;
	}
	.logoSection {
		flex-grow: 1;
		display: flex;
		align-items: center;
		padding: 1rem;
		p {
			font-size: 2rem;
			font-weight: 500;
		}
		img {
			height: 100%;
			width: auto;
			background: whitesmoke;
			border-radius: 50%;
			margin-right: 1rem;
		}
	}
	.linksSection {
		display: flex;
		width: 25%;
		align-items: center;
		justify-content: space-evenly;
		text-transform: capitalize;
	}
	@media screen and (max-width: 768px) {
		.linksSection {
			a,
			p {
				display: none;
			}
			a:nth-child(2) {
				display: block;
			}
		}
	}
}
