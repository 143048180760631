/** @format */

.bubleContainer {
	justify-self: start;
	width: 9rem;
	height: 9rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #008037;
	.bubleTitle {
		display: block;
		color: whitesmoke;
		font-size: 1.2rem;
		font-weight: 600;
	}
	.bubleTooltip {
		display: none;
	}
	&:hover {
		.bubleTitle {
			display: none;
		}
		.bubleTooltip {
			-webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
				both;
			animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
			pointer-events: none;
			display: flex;
			background-color: whitesmoke;
			border-radius: 1rem;
			width: 25vw;
			min-width: 150px;
			position: absolute;
			padding: 2rem;
			flex-direction: column;
			h2 {
				text-align: center;
				font-weight: 800;
				font-size: 1.5rem;
			}
			.line {
				background-color: #008037;
				height: 1px;
				margin-top: 1rem;
			}
			p {
				margin-top: 1rem;
			}
		}
	}
}

@-webkit-keyframes bubleContainer {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
@keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
