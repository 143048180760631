/** @format */

.home {
	display: flex;
	flex-direction: column;
	height: 95vh;
	background: whitesmoke;
	p {
		text-align: center;
		font-size: 24px;
		font-weight: 600;
	}
}
